import { loggerFactory } from "src/logging";
import type { GtmEvent } from "src/types";
import { isDevelopment } from "./appVersion";
import { isNullish } from "./typeGuards";

type LogGtmEvent = (
    event: GtmEvent & {
        onlyOnce?: boolean;
    },
) => void;

export const logGtmEvent: LogGtmEvent = (event) => {
    if (!window.dataLayer) {
        loggerFactory.getLogger("utils/gtm").error("Google Tag Manager has not been initialized!");
        return;
    }

    if (isDevelopment) return;

    if (event.onlyOnce) {
        const eventIdentifier = JSON.stringify(event);
        const localStorageKey = `gtm-event-${eventIdentifier}`;
        const hasEventBeenLogged = localStorage.getItem(localStorageKey);

        if (isNullish(hasEventBeenLogged)) {
            localStorage.setItem(localStorageKey, "true");
            window.dataLayer.push(event);
        }
    } else {
        window.dataLayer.push(event);
    }
};
