import type { FC } from "react";
import { useForm } from "react-hook-form";

import { Button, CmsError, Input, PopupWindow, PublicLayout } from "src/components";
import { isCmsErrorResponseWithMessage, removeAuthToken, useForgotPasswordMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logger } from "./logger";

type FormFields = {
    email: string;
};

export const ForgotPasswordPage: FC = () => {
    const { t } = useTranslation();

    const [forgotPassword, { isLoading, isError, error }] = useForgotPasswordMutation();

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>();

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            await forgotPassword(data).unwrap();
            await PopupWindow.fire({
                title: t("forgotPassword.success.popUp.title"),
                text: t("forgotPassword.success.popUp.message"),
                icon: "success",
                confirmButtonText: t("forgotPassword.success.popUp.confirm"),
            });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <PublicLayout title={t("forgotPassword.title")}>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
                {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
                <Input
                    type="text"
                    label={t("forgotPassword.email.placeholder")}
                    {...register("email", { required: true })}
                    error={errors.email?.message}
                />

                <Button type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                    {t("forgotPassword.button.send")}
                </Button>
            </form>
        </PublicLayout>
    );
};
