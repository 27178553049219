import type { FC, ReactNode } from "react";

import { cn } from "src/utils";
import { Link } from "./Link";

type DownloadLinkProps = {
    href: string;
    children?: ReactNode;
    onClick?: () => void;
    className?: string;
};

export const DownloadLink: FC<DownloadLinkProps> = ({ href, children, onClick, className }) => {
    const handleClick = () => {
        onClick?.();
        window?.kmpJsBridge?.callNative(
            "OpenExternal",
            JSON.stringify({
                url: href,
                timestamp: new Date().toISOString(),
            }),
        );
    };

    return window?.kmpJsBridge ? (
        <button
            type="button"
            className={cn(
                "inline-flex fill-brand-link text-sm text-link transition-colors duration-200 ease-in-out hover:fill-brand hover:text-link-hover",
                className,
            )}
            onClick={handleClick}
        >
            {children}
        </button>
    ) : (
        <Link href={href} className={className} onClick={onClick} newTab isExternal>
            {children}
        </Link>
    );
};
