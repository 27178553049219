import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getValOrThrowError } from "utils/fn";

import { Badge, Button, CmsError, CurrencySelector, Input, LanguageSwitcher, PageLayout } from "src/components";
import { Routes } from "src/routes";
import { useUpdateUserProfileMutation, useUserQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type UserProfileCurrencyEnum1, UserVerificationTypeEnum } from "src/types";
import { useUserVerification } from "src/verification";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { ReferralCode } from "./ReferralCode";

export const SettingsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isVerificationDone, isVerificationLoading } = useUserVerification();
    const { data: user, isLoading: isUserLoading, isError: isUserError, error: userError } = useUserQuery();
    const [updateProfile] = useUpdateUserProfileMutation();

    const handleCurrencyChange = async (currency: string) => {
        await updateProfile({
            profileDocumentId: getValOrThrowError(user?.userProfile.documentId).toString(),
            data: { currency: currency as UserProfileCurrencyEnum1 },
        });
    };

    const hasKyc = isVerificationDone(UserVerificationTypeEnum.KYC);
    const hasAml = isVerificationDone(UserVerificationTypeEnum.AML);

    return (
        <PageLayout title={t("settings.title")} loading={isVerificationLoading || isUserLoading}>
            <div className="grid w-full max-w-[444px] gap-6">
                {isUserError ? (
                    <CmsError error={userError} fallbackMessage={t("common.error")} />
                ) : (
                    <>
                        <p className="prose-text-small">{t("settings.deactivateAccount")}</p>
                        <div className="flex flex-row flex-wrap gap-10">
                            <div className="grid gap-2">
                                <p className="prose-text-small">{t("settings.verifyIdentity")}</p>
                                <div className="flex flex-row items-center gap-5">
                                    <Badge intent={hasKyc ? "subtleSuccess" : "subtleWarning"} className="w-fit">
                                        {hasKyc ? t("settings.completed") : t("settings.notCompleted")}
                                    </Badge>
                                </div>
                            </div>
                            <div className="grid gap-2">
                                <p className="prose-text-small">{t("settings.AML")}</p>
                                <div className="flex flex-row items-center gap-5">
                                    <Badge intent={hasAml ? "subtleSuccess" : "subtleWarning"} className="w-fit">
                                        {hasAml ? t("settings.completed") : t("settings.notCompleted")}
                                    </Badge>
                                </div>
                            </div>
                        </div>
                        {(!hasKyc || !hasAml) && (
                            <Button kind="outline" onClick={() => navigate(Routes.verifications.path)}>
                                {t("settings.fillNow")}
                            </Button>
                        )}
                        <hr className="grow border-t border-primary" />
                        <ReferralCode />
                        <hr className="grow border-t border-primary" />
                        <div className="flex">
                            <div className="flex-1">
                                <p className="text-sm">{t("settings.language")}</p>
                                <LanguageSwitcher className="p-0 pt-4" />
                            </div>
                            <CurrencySelector
                                className="flex-1"
                                contentPosition="popper"
                                label={t("settings.currency.label")}
                                additionalInfo={t("settings.currency.text")}
                                value={user?.userProfile?.currency}
                                onChange={handleCurrencyChange}
                            />
                        </div>
                        <hr className="grow border-t border-primary" />
                        <Input disabled label={t("settings.email")} value={user?.email} />
                        <ChangePasswordForm />
                    </>
                )}
            </div>
        </PageLayout>
    );
};
