import type { FC } from "react";

import { LoadingSpinner } from "src/icons";
import { cn } from "src/utils";

type LoadingProps = Readonly<{
    isFullPage?: boolean;
    className?: string;
}>;

export const Loading: FC<LoadingProps> = ({ isFullPage, className }) => (
    <div className={cn("flex w-full flex-1 items-center justify-center text-brand", isFullPage ? "h-screen" : "h-full", className)}>
        <LoadingSpinner className="w-16" />
    </div>
);
