import { GOOGLE_SIGNUP_KEY } from "./constants";

export const storeGoogleSignUpState = (referralCode?: string) => localStorage.setItem(GOOGLE_SIGNUP_KEY, referralCode ?? "true");

export const getGoogleSignUpState = () => {
    const googleSignUpState = localStorage.getItem(GOOGLE_SIGNUP_KEY);
    localStorage.removeItem(GOOGLE_SIGNUP_KEY);

    return {
        isSignUp: !!googleSignUpState,
        referralCode: googleSignUpState === "true" ? undefined : googleSignUpState ?? undefined,
    };
};
