import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Heading, PublicLayout } from "src/components";

type SignUpSuccessProps = Readonly<{
    email: string;
}>;

export const SignUpSuccess: FC<SignUpSuccessProps> = ({ email }) => {
    const { t } = useTranslation();

    return (
        <PublicLayout>
            <div className="w-full space-y-6">
                <Heading level={2}>{t("signUp.success.card.header")}</Heading>
                <div className="space-y-6">
                    <p>{t("signUp.success.message.email", { email })}</p>
                    <p>{t("signUp.success.message.verification")}</p>
                </div>
            </div>
        </PublicLayout>
    );
};
