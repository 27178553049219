import type { FC } from "react";

import { isCmsErrorResponseWithMessage, isCoreErrorResponseWithMessage } from "src/serverApi";
import { useTranslation } from "src/translations";
import { cn } from "src/utils";

type FetchErrorProps = Readonly<{
    error: unknown;
    fallbackMessage: string;
    className?: string;
}>;

// TODO: Use this component instead of CmsError
export const FetchError: FC<FetchErrorProps> = ({ error, fallbackMessage, className }) => {
    const { t } = useTranslation();

    let errorMessage = fallbackMessage;

    if (isCmsErrorResponseWithMessage(error)) {
        const translationKey = `cmsError.${cleanErrorMessage(error.data.error.message)}`;
        errorMessage = t(translationKey, error.data.error.message);
    }

    if (isCoreErrorResponseWithMessage(error)) {
        const translationKey = `coreError.${cleanErrorMessage(error.error)}`;
        errorMessage = t(translationKey, error.error);
    }

    return <div className={cn("bg-brand-error/20 p-2.5 text-center text-error", className)}>{errorMessage}</div>;
};

const cleanErrorMessage = (errorMessage: string): string =>
    errorMessage
        .replace(/[^A-Za-z0-9\s]/g, "") // remove special characters
        .replace(/\s+/g, "_"); // replace spaces with underscores
