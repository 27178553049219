import type { FC } from "react";

import { Button, PopupWindow, Tooltip } from "src/components";
import { InfoIcon } from "src/icons";
import { Routes } from "src/routes";
import { getUser } from "src/serverApi";
import { useTranslation } from "src/translations";
import { useAppSelector } from "src/utils";

export const ReferralCode: FC = () => {
    const { t } = useTranslation();
    const user = useAppSelector(getUser);

    const copyLinkToClipboard = () => {
        const link = `${window.location.origin}${Routes.signUp.path}?referralCode=${user.userProfile.referralCode}`;
        navigator.clipboard.writeText(link);
        PopupWindow.fire({
            title: t("settings.referralCode.copySuccess.title"),
            text: t("settings.referralCode.copySuccess.text"),
        });
    };

    return (
        <div className="flex flex-col flex-wrap gap-2">
            <p className="flex items-center gap-1 text-sm">
                <span>{t("settings.referralCode.label")}</span>
                <Tooltip content={t("settings.referralCode.description")}>
                    <span>
                        <InfoIcon className="w-[18px]" />
                    </span>
                </Tooltip>
            </p>

            <p className="mb-2 text-xl font-bold">{user.userProfile.referralCode}</p>
            <Button kind="outline" onClick={copyLinkToClipboard}>
                {t("settings.referralCode.copy")}
            </Button>
        </div>
    );
};
