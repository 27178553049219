import type { FC } from "react";

import { cn } from "src/utils";
import { Logo } from "../Logo";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { menuItems } from "./menuItems";
import { NavigationMenuItem } from "./NavigationMenuItem";
import { UserSection } from "./UserSection";

type NavigationMenuDesktopProps = Readonly<{
    className?: string;
}>;

export const NavigationMenuDesktop: FC<NavigationMenuDesktopProps> = ({ className }) => (
    <nav className={cn("fixed flex h-full w-left-menu-width flex-col border-r border-primary bg-subtle font-inter font-medium", className)}>
        <div className="pb-5 pl-[22px] pr-[74px] pt-10">
            <Logo />
        </div>
        <div className="flex grow flex-col border-b border-b-primary pb-5 pl-5">
            <ul className="flex grow flex-col gap-2.5 pb-2 pt-5">
                {menuItems.map((menuItem) => (
                    <NavigationMenuItem className="last:mt-auto" key={menuItem.route.path} {...menuItem} />
                ))}
            </ul>
            <LanguageSwitcher />
        </div>
        <UserSection />
    </nav>
);
