import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { SecuredContent } from "src/components";
import type { ApplicationModule } from "src/types";
import { articleDetail, howTo, investorClub, news } from "./articles";
import { dashboard } from "./dashboard";
import { earnings } from "./earnings";
import { orderDetail, orderPay, orders, orderWalletAuthorize } from "./orders";
import { projectDetail, projectOrder, projects } from "./projects";
import { emailVerified, forgotPassword, googleCallback, newPassword, signIn, signUp } from "./public";
import { settings } from "./settings";
import { verification } from "./verification";
import { voting } from "./voting";

const Modules: ReadonlyArray<ApplicationModule> = [
    dashboard,
    earnings,
    orders,
    orderWalletAuthorize,
    orderDetail,
    orderPay,
    projects,
    projectDetail,
    projectOrder,
    settings,
    voting,
    howTo,
    articleDetail,
    investorClub,
    news,
    forgotPassword,
    newPassword,
    signIn,
    signUp,
    emailVerified,
    verification,
    googleCallback,
];

export const AppRouter = () => (
    <BrowserRouter>
        <Routes>
            {Modules.map(({ NAME, route, Container, secure }) => {
                if (route?.path && Container) {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={secure === false ? <Container /> : <SecuredContent Component={Container} />}
                        />
                    );
                }
                throw new Error(`Module ${NAME} could not be used as route because it has no route or container!`);
            })}
            <Route path="*" element={<Navigate to={projects.route.path} />} />
        </Routes>
    </BrowserRouter>
);
