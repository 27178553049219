import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { GlobeIcon, type IconType, MoneyBagIcon, SafeIcon, WalletIcon } from "src/icons";
import { type LocKey, useTranslation } from "src/translations";
import { Currency, type UserPortfolio } from "src/types";
import { isNotNullish, multiplyPriceInCents } from "src/utils";

type DashboardStatsProps = Readonly<{
    currency: string;
    earnings?: number;
    portfolio?: UserPortfolio;
    czkWalletBalance?: number;
    eurWalletBalance?: number;
}>;

export const DashboardStats: FC<DashboardStatsProps> = ({ portfolio, currency, earnings, czkWalletBalance, eurWalletBalance }) => {
    const { t } = useTranslation();
    const { investedAmount: invested, countriesCount: noCountries } = portfolio ?? {};

    const statsList: ReadonlyArray<{ Icon: IconType; locKey: LocKey; value: string }> = [
        {
            Icon: SafeIcon,
            locKey: "dashboard.stats.invested",
            value: t("format.currency", { value: multiplyPriceInCents(invested), currency }),
        },
        {
            Icon: MoneyBagIcon,
            locKey: "dashboard.stats.earnings",
            value: isNotNullish(earnings) ? t("format.currency", { value: multiplyPriceInCents(earnings), currency }) : "",
        },
        {
            Icon: WalletIcon,
            locKey: "dashboard.stats.walletCzk",
            value: t("format.currency", { value: multiplyPriceInCents(czkWalletBalance), currency: Currency.CZK }),
        },
        {
            Icon: WalletIcon,
            locKey: "dashboard.stats.walletEur",
            value: t("format.currency", { value: multiplyPriceInCents(eurWalletBalance), currency: Currency.EUR }),
        },
        { Icon: GlobeIcon, locKey: "dashboard.stats.noCountries", value: String(noCountries) },
    ];

    return (
        <div className="flex flex-wrap gap-x-1 gap-y-3">
            {statsList.map(({ Icon, locKey, value }) => (
                <div className="flex flex-1 basis-[220px] gap-4 max-xl:basis-1/2 max-md:basis-full" key={locKey}>
                    <div className="rounded border border-subtle p-3">
                        <Icon className="size-6" />
                    </div>
                    <div className="flex flex-col font-inter">
                        <span className="text-sm">{t(locKey)}</span>
                        {value === "" ? <Skeleton className="h-[28px]" /> : <span className="text-2xl">{value} </span>}
                    </div>
                </div>
            ))}
        </div>
    );
};
