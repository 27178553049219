import type { FaqListResponse, GetFaqsParams } from "src/types";
import { rootApi } from "../rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, FAQ_TAG } from "../tags";
import { getCmsUrl } from "../utils";

export const faqApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        faqs: build.query<FaqListResponse, GetFaqsParams>({
            query: (params) => ({
                url: getCmsUrl("/api/faqs"),
                params,
            }),
            providesTags: [FAQ_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
    }),
});

export const { useFaqsQuery } = faqApi;
