import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, PopupWindow, TopBar } from "src/components";
import { WalletIcon } from "src/icons";
import { Routes } from "src/routes";
import { useAuthorizeWalletPaymentMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { getValOrThrowError, useComponentDidMount } from "src/utils";
import { logger } from "./logger";

export const OrderWalletAuthorizePage: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const orderDocumentId = getValOrThrowError(Routes.orderWalletAuthorize.useParams().orderDocumentId, "Missing order document id");
    const walletAuthorizeToken = getValOrThrowError(
        Routes.orderWalletAuthorize.useParams().walletAuthorizeToken,
        "Missing authorize token",
    );

    const [authorizeWalletPayment] = useAuthorizeWalletPaymentMutation();

    const authorizePayment = async () => {
        try {
            await authorizeWalletPayment({ token: walletAuthorizeToken }).unwrap();
            navigate(`${Routes.orderPay.fillPathParams({ orderDocumentId })}?checking=true`);
        } catch (error) {
            logger.error("Error while authorizing wallet payment", error);
            await PopupWindow.fire({
                title: t("orders.walletAuthorize.error.title"),
                text: t("orders.walletAuthorize.error.text"),
            });
            navigate(Routes.orders.path);
        }
    };

    useComponentDidMount(() => {
        authorizePayment();
    });

    return (
        <div className="flex min-h-screen flex-col">
            <TopBar />
            <div className="flex flex-1 items-center justify-center">
                <div className="mt-[-40px] grid h-full flex-1 items-center justify-center justify-items-center gap-3 p-4 sm:grid-cols-[min-content_,auto]">
                    <WalletIcon className="size-[70px] animate-pulse stroke-brand-primary" />
                    <div className="grid gap-4 sm:gap-0">
                        <Heading level={2} className="text-balance text-center normal-case sm:text-left">
                            {t("orders.walletAuthorize.processing.title")}
                        </Heading>
                        <p className="text-center sm:text-left">{t("orders.walletAuthorize.processing.text")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
