import type { AppSelector, User } from "src/types";
import { Currency } from "src/types";
import { getValOrThrowError } from "src/utils";
import { authApi } from "./queries";

const getUserQueryState = authApi.endpoints.user.select();

// TODO: use this where needed
export const getUser: AppSelector<User> = (state) => getValOrThrowError(getUserQueryState(state).data, "User not found!");
export const getOptionalUser: AppSelector<User | undefined> = (state) => getUserQueryState(state).data;

export const getUserCurrency: AppSelector<Currency> = (state) => getUser(state).userProfile.currency ?? Currency.EUR;
