import { type FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, Loading, OrderLayout, PopupWindow, TopBar, VerificationStepper } from "src/components";
import { getCurrencyConversionRate } from "src/config";
import { Routes } from "src/routes";
import { getUserCurrency, useGetAvailableTokensCountByProjectDocumentIdQuery, useProjectInfoDetailByDocumentIdQuery } from "src/serverApi";
import { useDocumentIdFromSlug, useTranslation } from "src/translations";
import { ProjectInfoStateEnum, ProjectStateEnum, UserVerificationTypeEnum } from "src/types";
import {
    convertPriceInCentsToTargetCurrencyWithCentMultiply,
    getCurrentNominalPrice,
    getValOrThrowError,
    isNotNullish,
    useAppSelector,
} from "src/utils";
import { useUserVerification } from "src/verification";
import { OrderForm } from "./OrderForm";

export const OrderPage: FC = () => {
    const queryParams = Routes.projectOrder.useParams();
    const projectSlug = getValOrThrowError(queryParams.projectSlug, "Missing project slug");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isVerificationDone, isVerificationLoading } = useUserVerification();
    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);

    const projectInfoDocumentId = useDocumentIdFromSlug(
        projectSlug,
        "api::project-info.project-info",
        (translatedSlug) => {
            navigate(Routes.projectOrder.fillPathParams({ projectSlug: translatedSlug }), { replace: true });
        },
        () => {
            PopupWindow.fire({
                title: t("projects.order.failure.projectNotFound.title"),
                text: t("projects.order.failure.projectNotFound.text"),
                confirmButtonText: t("projects.order.failure.projectNotFound.buttonText"),
            }).then(() => navigate(Routes.projects.path));
        },
    );

    const { data: projectInfo, isLoading: projectDetailLoading } = useProjectInfoDetailByDocumentIdQuery(projectInfoDocumentId!, {
        skip: !projectInfoDocumentId,
    });
    const { data: availableTokensCount, isLoading: tokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(
        projectInfo?.project?.documentId!,
        {
            skip: !projectInfo?.project?.documentId,
        },
    );

    const project = projectInfo?.project;
    const projectStatus = project?.state ?? ProjectStateEnum.Unknown;

    const isUserInvestor = useMemo(
        () => (isVerificationLoading ? false : isVerificationDone(UserVerificationTypeEnum.INVESTOR)),
        [isVerificationLoading, isVerificationDone],
    );
    const preventBuying = project?.onlyForCurrentInvestor === true && !isUserInvestor;

    const tokenFee = project?.tokenFee ?? 0;
    const tokenPriceWithoutFee = getCurrentNominalPrice(project?.tokenCurrentNominalPrice);
    const tokenPrice = tokenPriceWithoutFee ? tokenPriceWithoutFee + tokenFee : null;
    const convertedTokenPrice =
        tokenPrice && project?.currency
            ? convertPriceInCentsToTargetCurrencyWithCentMultiply(tokenPrice, project.currency, userCurrency, conversionRate)
            : null;

    useEffect(() => {
        if (!isVerificationLoading && !projectDetailLoading && projectInfoDocumentId) {
            const isSoon = projectStatus === ProjectInfoStateEnum.Prepared || projectStatus === ProjectInfoStateEnum.Unknown;
            if (isSoon) {
                PopupWindow.fire({
                    title: t("projects.order.failure.soon.title"),
                    text: t("projects.order.failure.soon.text"),
                    confirmButtonText: t("projects.order.failure.soon.buttonText"),
                }).then(() => navigate(Routes.projectDetail.fillPathParams({ projectSlug })));
            } else if (projectStatus === ProjectInfoStateEnum.Closed) {
                PopupWindow.fire({
                    title: t("projects.order.failure.closed.title"),
                    text: t("projects.order.failure.closed.text"),
                    confirmButtonText: t("projects.order.failure.closed.buttonText"),
                }).then(() => navigate(Routes.projectDetail.fillPathParams({ projectSlug })));
            } else if (preventBuying) {
                PopupWindow.fire({
                    title: t("projects.order.failure.investorNeeded.title"),
                    text: t("projects.order.failure.investorNeeded.text"),
                    confirmButtonText: t("projects.order.failure.investorNeeded.buttonText"),
                }).then(() => navigate(Routes.projectDetail.fillPathParams({ projectSlug })));
            }
        }
    }, [projectInfoDocumentId, isVerificationLoading, navigate, preventBuying, projectDetailLoading, projectSlug, projectStatus, t]);

    if (projectDetailLoading || tokensLoading || !projectInfoDocumentId) {
        return <Loading isFullPage />;
    }

    return (
        <>
            <div className="sticky top-0 bg-brand-white">
                {!isUserInvestor && <VerificationStepper activeVerificationType={UserVerificationTypeEnum.ORDER} />}
                <TopBar />
            </div>
            <OrderLayout title={t("projects.order.heading")} projectInfo={projectInfo} openInNewTab>
                <Heading level={6} className="mb-[3px] normal-case">
                    {t("common.overview")}
                </Heading>
                <div className="!mb-5 grid grid-cols-2 gap-y-1.5 text-sm">
                    <div>{t("projects.order.share.available")}</div>
                    <div className="text-right">
                        {availableTokensCount ? t("format.amount", { value: availableTokensCount, maximumFractionDigits: 0 }) : "-"}
                    </div>
                    <div>{t("projects.order.share.price")}</div>
                    <div className="text-right">
                        {isNotNullish(convertedTokenPrice) && t("format.currency", { value: convertedTokenPrice, currency: userCurrency })}
                    </div>
                    <div>{t("projects.order.share.min")}</div>
                    <div className="text-right">
                        {project?.minPurchaseQty ? t("format.amount", { value: project.minPurchaseQty, maximumFractionDigits: 0 }) : "-"}
                    </div>
                </div>
                {isNotNullish(tokenPrice) && isNotNullish(project) && (
                    <OrderForm availableShare={availableTokensCount ?? 0} project={project} tokenPrice={tokenPrice} />
                )}
            </OrderLayout>
        </>
    );
};
