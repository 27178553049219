import type { FC } from "react";

import { isProjectInInvestState } from "src/app/projects/detail/utils";
import { useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { type ProjectInfo, ProjectInfoStateEnum } from "src/types";
import { ProjectDetailCalculator } from "./Calculator";
import { Documents } from "./Documents";
import { FundedRequestInfo } from "./FundedRequestInfo";
import { HowItWorksStepper } from "./HowItWorksStepper";

type ProjectDetailSidebarProps = Readonly<{
    loading: boolean;
    projectInfo?: ProjectInfo;
}>;

export const ProjectDetailSidebar: FC<ProjectDetailSidebarProps> = ({ loading, projectInfo }) => {
    const { data: availableTokenCount, isLoading: areTokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(
        projectInfo?.project?.documentId!,
        { skip: !projectInfo?.project?.documentId },
    );

    const areTokensAvailable = !areTokensLoading && availableTokenCount !== undefined && availableTokenCount > 0;
    const isInInvestState = isProjectInInvestState(projectInfo?.project?.state ?? ProjectInfoStateEnum.Unknown);

    return (
        <div className="flex h-full flex-col">
            {areTokensAvailable && (
                <ProjectDetailCalculator loading={loading} projectInfo={projectInfo} isProjectInInvestState={isInInvestState} />
            )}

            {!areTokensLoading && !areTokensAvailable && projectInfo?.project?.documentId && isInInvestState && (
                <FundedRequestInfo loading={loading} projectDocumentId={projectInfo?.project?.documentId} />
            )}

            <div className="mt-8 flex flex-col sm:mt-12 lg:gap-12">
                {projectInfo?.project?.id && (
                    <Documents
                        isLoading={loading}
                        projectId={projectInfo.project?.id}
                        informationProspect={projectInfo?.informationProspect}
                        documents={projectInfo?.documents}
                    />
                )}
                <HowItWorksStepper />
            </div>
        </div>
    );
};
