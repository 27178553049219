import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, TRANSLATED_SLUG_TAG } from "../tags";
import { getCmsUrl } from "../utils";

export const toolApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        parseSlug: build.query<{ slug: string; documentId: string }, { slug: string; apiName: string }>({
            query: (params) => ({
                url: getCmsUrl("/api/tools/parse-slug"),
                method: HttpMethod.GET,
                params,
            }),
            providesTags: [TRANSLATED_SLUG_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
    }),
});

export const { useParseSlugQuery } = toolApi;
