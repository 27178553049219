import type { FC } from "react";

import { ProjectLink, TableCell, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import type { Order } from "src/types";
import { getValOrThrowError, multiplyPriceInCents } from "src/utils";
import { OrderMenu } from "./OrderMenu";
import { OrderStatus } from "./OrderStatus";

type OrderItemProps = Readonly<{
    order: Order;
}>;

export const OrderItem: FC<OrderItemProps> = ({ order }) => {
    const { t } = useTranslation();

    const { project, createdAt, price, state, tokenCount, documentId, currency, id, paymentType } = order;

    const createdAtDate = new Date(getValOrThrowError(createdAt, "Missing createdAt date for order"));

    return (
        <TableRow className="font-inter text-base text-primary [&>td]:px-3 [&>td]:py-1.5 [&>td]:first:pt-3">
            <TableCell className="whitespace-nowrap pl-0">
                # {`${createdAtDate.getFullYear()}${createdAtDate.getMonth()}${createdAtDate.getDay()}${project?.id}${id}`}
            </TableCell>
            <TableCell>
                <ProjectLink projectSlug={project?.projectInfo?.slug} projectTitle={project?.projectInfo?.title} />
            </TableCell>
            <TableCell className="text-center">{t("format.localDate", { date: createdAtDate })}</TableCell>
            <TableCell className="text-center">{t("format.amount", { value: tokenCount })}</TableCell>
            <TableCell className="text-right">{t("format.currency", { value: multiplyPriceInCents(price), currency })}</TableCell>
            <TableCell>
                <OrderStatus state={state!} />
            </TableCell>
            <TableCell>{paymentType ? t(`orders.list.paymentTypeEnum.${paymentType}`) : null}</TableCell>
            <TableCell className="pr-0 text-right">
                <OrderMenu documentId={getValOrThrowError(documentId, "Missing document id for order")} state={state!} />
            </TableCell>
        </TableRow>
    );
};
