/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Document ID of an entity saved in CMS.
 * @example "ba5okl4lwi3ez8yqhjxujtjm"
 */
export type CmsDocumentId = string;

/**
 * Database ID of an entity saved in CMS.
 * @format int64
 * @example 1
 */
export type CmsDatabaseId = number;

/**
 * Amount in lowest currency unit (e.g. `100` for `CZK` represents `1,-`).
 * @format int64
 * @example 123400
 */
export type CoreApiAmount = number;

export enum CoreApiCurrency {
    CZK = "CZK",
    EUR = "EUR",
}

export enum CoreApiLanguage {
    Cs = "cs",
    En = "en",
}

export interface WelcomeDto {
    /** @example "Welcome to investbay-core" */
    message: string;
    /** @example "investbay-core" */
    name: string;
    /** @example "Core application of investbay" */
    description: string;
    /** @example "1.1.0" */
    version: string;
    /** @example "/actuator/health" */
    healthCheck: string;
    /** @example "/swagger-ui/index.html" */
    swagger: string;
}

/** This should correspond to an event described  [here](https://docs.strapi.io/dev-docs/backend-customization/webhooks#payloads). */
export interface StrapiWebHook {
    /** Type of the event. */
    event: string;
    /**
     * Timestamp of the event.
     * @format date-time
     */
    createdAt: string;
    /** Affected domain object. */
    model: string;
    entry: Record<string, any>;
}

/** Sign up data available for the user. */
export interface StrapiForm {
    /**
     * E-mail of the user.
     * @format email
     */
    email: string;
    /**
     * Hubspot HUTK cookie.
     * @example "9970c73472b9c359e72e603a4ccb329a"
     */
    hsCookieId?: string;
}

/** Request to create `count` tokens for project with document ID `projectDocumentId`. */
export interface CreateTokensRequest {
    /** Document ID of an entity saved in CMS. */
    projectDocumentId: CmsDocumentId;
    /**
     * Count of the tokens to create.
     * @format int32
     * @example 200000
     */
    count: number;
}

export interface BankTransferDetails {
    /** Order reference code to use as "variabilní symbol" for Czech domestic transfers or "reference" for SEPA payments. */
    orderReferenceCode: string;
    /** Message for the receiver. */
    messageForReceiver: string;
    /**
     * Price of the order aka amount to pay.
     * @format double
     */
    amount: number;
    currency: CoreApiCurrency;
    /** Name of the receiver the transfer should be made for. */
    receiver: string;
    /**
     * URI for QR code payment.  May not be supported by all banks but should work for Czech banks for orders paid in `CZK`. It is not returned if QR code is not enabled on core.
     * @format uri
     */
    qrCodeUri?: string;
    /** IBAN account number to pay to. */
    accountIban: string;
    /** Optional account number used in Czech Republic to pay to. */
    accountCzech?: string;
    /** Optional SWIFT/BIC account number to pay to. */
    accountSwift?: string;
}

export interface PayoutPaymentGatewayEnvelope {
    /** Document ID of an entity saved in CMS. */
    orderDocumentId: CmsDocumentId;
    /**
     * URI where to redirect the user after the payment has been processed.
     * @format uri
     */
    returnUri: string;
}

export interface PayoutPaymentGatewayUri {
    /**
     * URI where to redirect the user to process the payment.
     * @format uri
     */
    uri: string;
}

export interface ConfirmoPaymentGatewayEnvelope {
    /** Document ID of an entity saved in CMS. */
    orderDocumentId: CmsDocumentId;
    /**
     * URI where to redirect the user after the payment has been processed.
     * @format uri
     */
    returnUri: string;
}

export interface ConfirmoPaymentGatewayUri {
    /**
     * URI where to redirect the user to process the payment.
     * @format uri
     */
    uri: string;
}

export interface KycWellKnownCountries {
    /**
     * Collection of all countries known by the core.
     * @uniqueItems true
     */
    countries: KycWellKnownCountry[];
}

export interface KycWellKnownCountry {
    /**
     * ISO 3166-1 alpha-2 country code.
     * @minLength 2
     * @maxLength 2
     * @example "CZ"
     */
    alpha2: string;
    /**
     * ISO 3166-1 alpha-3 country code.
     * @minLength 3
     * @maxLength 3
     * @example "CZE"
     */
    alpha3: string;
}

export enum AuthologicFlow {
    Bankid = "bankid",
    Idcards = "idcards",
}

export interface AuthologicKycEnvelope {
    /** Database ID of an entity saved in CMS. */
    userId: CmsDatabaseId;
    /**
     * URI where to redirect the user after the KYC has been processed.
     * @format uri
     */
    returnUri: string;
}

export interface AuthologicKycResponse {
    /**
     * URI where to redirect the user to process the KYC.
     * @format uri
     */
    uri: string;
    /** Conversation ID as returned by Authologic. */
    conversationId: string;
}

export interface SumSubTokenResponse {
    /**
     * User ID (an e-mail) of the user the token has been assigned to.
     * @example "email@strapi.user"
     */
    userId: string;
    /** Whether the verification must be performed or not since SumSub has the data already. */
    verification: SumSubTokenVerification;
    /**
     * Token generated by SumSub when `verification` is `Required`. Otherwise `null`.
     * @example "_act-sbx-jwt-eyJhbGciOiJub25lIn0.eyJqdGkiOiJfYWN0LXNieC04NzMzM2E0Ny02MjY3LTQ0ZWMtODU3Zi01N2E5YzMyYzFlMGMtdjIiLCJ1cmwiOiJodHRwczovL2FwaS5zdW1zdWIuY29tIn0.-v2"
     */
    token?: string;
}

/** Whether the verification must be performed or not since SumSub has the data already. */
export enum SumSubTokenVerification {
    Required = "Required",
    AlreadyDone = "AlreadyDone",
}

/**
 * ID of the wallet as used by account-service.
 * @example "019352EEDF507EACAE20245556AAAB8A"
 */
export type WalletId = string;

export interface WalletBalanceResponse {
    /**
     * Balance of the wallet.
     * @format int64
     * @example 123400
     */
    balance: number;
    /** Currency of the wallet. */
    currency: CoreApiCurrency;
    /** ID of the wallet as used by account-service. */
    walletId: WalletId;
}

export interface WalletPaymentEnvelope {
    /** Document ID of an entity saved in CMS. */
    orderDocumentId: CmsDocumentId;
    /** ID of the wallet as used by account-service. */
    walletId: WalletId;
    /**
     * Amount to pay from the wallet.
     * @format int64
     * @example 123400
     */
    amount: number;
}

/** @format walletPaymentResponse */
export type WalletPaymentResponse = object;

export interface PartialWalletPaymentResponse {
    /** Document ID of an entity saved in CMS. */
    orderDocumentId: CmsDocumentId;
    /**
     * Remaining amount of the order which must be paid using other payment method.
     * @format int64
     * @example 15000
     */
    remainingAmount: number;
}

export interface FullWalletPaymentResponse {
    /**
     * Token identifying the payment.
     * User has to confirm the payment using the token by calling other endpoint.
     * @format uuid
     */
    token: string;
}

export interface EarningsImportsResponse {
    data: EarningsImportResponse[];
}

/** Source of the earnings in the CSV file. */
export enum EarningsImportType {
    Operation = "Operation",
    Sale = "Sale",
}

export interface EarningsImportResponse {
    /** Type of the import. */
    type: EarningsImportResponseTypeEnum;
    /**
     * Reference code of the import (as received in the request).
     * @example "2025-01"
     */
    referenceCode: string;
    /**
     * Timestamp when the import has been received.
     * @format date-time
     */
    createdAt: string;
    /**
     * Timestamp when the import should have been credited.
     * @format date-time
     */
    paidAt: string;
    state: EarningsImportResponseStateEnum;
    /**
     * Description of an error why the import failed.
     * Available only when `state` is `Error`.
     * @example "Unparsable data on line 3 - amount is not a number."
     */
    error?: string;
    /**
     * Amount of `Deposit` or `Withdraw`.
     * It is `null` for `Csv`.
     */
    amount?: CoreApiAmount;
    /**
     * Currency of `Deposit` or `Withdraw`.
     * It is `null` for `Csv`.
     */
    currency?: CoreApiCurrency;
    /**
     * E-mail of user the `Deposit` or `Withdraw` has been performed for.
     * It is `null` for `Csv`.
     * @format email
     */
    userEmail?: string;
}

export interface UserEarningsPayoutsResponse {
    /**
     * ID of last performed earnings payout.
     * Null if no import has been performed yet.
     * @format uuid
     */
    lastImportId?: string;
    data: UserEarningsPayoutResponse[];
}

export interface UserEarningsPayoutResponse {
    /**
     * Timestamp when the earnings have been paid.
     * @format date-time
     */
    timestamp: string;
    /** Database id of the project the earnings have been paid for. */
    projectId: CmsDatabaseId;
    /**
     * Amount of the earning payout.
     * In cents, so `123400` is `1234,-`.
     * @format int64
     * @example 123400
     */
    amount: number;
    /** Currency of the earnings payout. */
    currency: CoreApiCurrency;
    /** How the earnings have been paid. */
    settlement: UserEarningsPayoutResponseSettlementEnum;
}

export interface WalletDepositEnvelope {
    /** Amount to deposit. */
    amount: CoreApiAmount;
    /** Currency of the `amount`. */
    currency: CoreApiCurrency;
    /** Reference code of the deposit. */
    referenceCode: string;
}

export interface WalletWithdrawEnvelope {
    /**
     * Amount to withdraw.
     * If not supplied, all funds will be withdrawn.
     */
    amount?: CoreApiAmount;
    /** Currency of the `amount`. */
    currency: CoreApiCurrency;
    /** Reference code of the withdraw. */
    referenceCode: string;
}

export interface OrderRefundEnvelope {
    /** Amount to refund to the user. */
    amount: CoreApiAmount;
    /** Currency of the order. */
    currency: CoreApiCurrency;
    /** Document ID of the order to be deleted. */
    orderDocumentId: CmsDocumentId;
    /**
     * E-mail of the user who the order belongs.
     * @format email
     */
    userEmail: string;
}

export interface BrokerCreateUserEnvelope {
    /**
     * E-mail address of the new user.
     * @format email
     * @example "new@user.email"
     */
    email: string;
    /**
     * Why the user is being created.
     * @example "Purchase of shares"
     */
    purposeOfContract: string;
    /** How much the user plans to spent. */
    expectedTradesVolume: BrokerCreateUserEnvelopeExpectedTradesVolumeEnum;
    /**
     * - `Welfare` includes alimony and pension,
     * - `Salary` when person is an employee,
     * - `Business` when person is an entrepreneur,
     * - `Capital` includes dividends, profit shares, property rentals, inheritance…
     * @uniqueItems true
     */
    originOfIncome: BrokerCreateUserEnvelopeOriginOfIncomeEnum[];
    /** Other income source which does not fit to `originOfIncome`. */
    originOfIncomeOther?: string;
    /**
     * Confirmation that the funds to be used for trading do not derive from criminal activity
     * and do not serve to finance terrorism and were acquired in accordance with the law.
     */
    fundsOrigin: BrokerCreateUserEnvelopeFundsOriginEnum;
    /**
     * Type of business or other activities of the user in countries or with entities from countries
     * against which the Czech Republic applies international sanctions.
     */
    businessActivities: BrokerCreateUserEnvelopeBusinessActivitiesEnum;
    /**
     * If user is politically exposed, fill this info.
     * Otherwise omit it.
     */
    politicalInfo?: {
        /**
         * - `Salary` when person is an employee,
         * - `Capital` includes dividends, profit shares, property rentals…
         * - `Business` when person is an entrepreneur,
         * @uniqueItems true
         */
        originOfIncome: BrokerCreateUserEnvelopeOriginOfIncomeEnum1[];
        /** Other income source which does not fit to `originOfIncome`. */
        originOfIncomeOther?: string;
    };
}

export interface BrokerCreateUserResponse {
    /** ID of the new user. */
    id: CmsDatabaseId;
    /**
     * URL where to perform KYC.
     * @format uri
     */
    kyc: string;
}

export interface BrokerGetProjectsResponse {
    projects: BrokerGetProjectsListResponse[];
}

export interface BrokerGetProjectsListResponse {
    /** ID of the project. */
    id: CmsDocumentId;
    /**
     * Name of the project.
     * @example "Example project name"
     */
    name: string;
    /**
     * Where the project is located.
     * Expressed as ISO 3166-1 alpha-2 code.
     * @example "Spain"
     */
    country: string;
    /**
     * Count of tokens of the project available for purchase.
     * @format int64
     * @example 123
     */
    availableTokens: number;
    /**
     * Price of one token of the project in selected currency.
     * The price is in cents.
     * @format int64
     * @example 100
     */
    oneTokenPrice: number;
    /** Currency of the price. */
    priceCurrency: CoreApiCurrency;
}

export type BrokerGetProjectResponse = BrokerGetProjectsListResponse & {
    /**
     * Summary of the project.
     * Formatted as HTML.
     * @example "<p>Very nice house on the beach.</p>"
     */
    summary?: string;
    /**
     * Descriptions of the project.
     * Formatted as HTML.
     * @example "<p>Welcome to the <i>beautiful</i> <b>Lake Garda</b>!
     * Our holiday home in Riva del Garda has all the amenities you need for a relaxing and unforgettable holiday.</p>
     *
     * <p>Enjoy the view of Lake Garda from the large garden and relax in your private pool on hot days.
     * The detached house
     * <ul>
     * <li>is 10 sqm,</li>
     * <li>it can accommodate up to 6 people</li>
     * <li>and is completely at your disposal.</li>
     * </ul>
     * </p>"
     */
    description?: string;
    images?: string[];
    documents?: string[];
};

export interface BrokerCreateOrderEnvelope {
    /** ID of the user. */
    userId: CmsDatabaseId;
    /** ID of the project to purchase tokens. */
    projectId: CmsDocumentId;
    /**
     * How many tokens to buy.
     * @format int64
     * @example 10
     */
    countOfTokens: number;
    currency: CoreApiCurrency;
}

export interface BrokerCreateOrderResponse {
    /** ID of the order. */
    orderId: CmsDocumentId;
}

/** Type of the import. */
export enum EarningsImportResponseTypeEnum {
    OperationEarnings = "OperationEarnings",
    SaleEarnings = "SaleEarnings",
    Deposit = "Deposit",
    Withdraw = "Withdraw",
}

export enum EarningsImportResponseStateEnum {
    Created = "Created",
    Processed = "Processed",
    Reversed = "Reversed",
    Error = "Error",
}

/** How the earnings have been paid. */
export enum UserEarningsPayoutResponseSettlementEnum {
    BankTransfer = "BankTransfer",
    DepositToWallet = "DepositToWallet",
}

/** How much the user plans to spent. */
export enum BrokerCreateUserEnvelopeExpectedTradesVolumeEnum {
    LessThan50000Czk = "LessThan50000Czk",
    Between50001And250000Czk = "Between50001And250000Czk",
    Between250001And1250000Czk = "Between250001And1250000Czk",
    Between1250001And5000000Czk = "Between1250001And5000000Czk",
    MoreThan5000000Czk = "MoreThan5000000Czk",
}

export enum BrokerCreateUserEnvelopeOriginOfIncomeEnum {
    Welfare = "Welfare",
    Salary = "Salary",
    Business = "Business",
    Capital = "Capital",
}

/**
 * Confirmation that the funds to be used for trading do not derive from criminal activity
 * and do not serve to finance terrorism and were acquired in accordance with the law.
 */
export enum BrokerCreateUserEnvelopeFundsOriginEnum {
    Legal = "Legal",
    Criminal = "Criminal",
}

/**
 * Type of business or other activities of the user in countries or with entities from countries
 * against which the Czech Republic applies international sanctions.
 */
export enum BrokerCreateUserEnvelopeBusinessActivitiesEnum {
    NotSubjectToSanctions = "NotSubjectToSanctions",
    SubjectToSanctions = "SubjectToSanctions",
}

export enum BrokerCreateUserEnvelopeOriginOfIncomeEnum1 {
    Salary = "Salary",
    Capital = "Capital",
    Business = "Business",
    Inheritance = "Inheritance",
}

export type GetWelcomeMessageData = WelcomeDto;

/**
 * @format binary
 * @example "openapi: 3.0.0
 * info:
 *   title: Interface of the MOFOP backend
 *   version: "1"
 * tags: …"
 */
export type GetOpenApiData = File;

export type CreateAuditLogData = any;

export type SynchronizeOrderWithHubspotData = any;

export type SynchronizeUserWithHubspotData = any;

export type CreateTokensData = any;

export type SynchronizeUserSignupWithHubspotFormData = any;

/**
 * @format binary
 * @example "user,project,tokens,amount,currency
 * foo@bar.baz,project-internal-name,10,,
 * …"
 */
export type PrepareEarningsCsvData = File;

export interface ProcessEarningsCsvPayload {
    /** Reference code of the import. */
    referenceCode: string;
    /**
     * CSV file with earnings to import.
     * @format binary
     */
    file: File;
    /**
     * Optional date which specifies when the earnings should be displayed as "paid out".
     * If not set, current date will be used.
     * @format date
     */
    date?: string;
    /** Source of the earnings in the CSV file. */
    earningsFrom: EarningsImportType;
}

export type ProcessEarningsCsvData = EarningsImportResponse;

export type GetEarningsImportsHistoryData = EarningsImportsResponse;

export type GetSpecifiedUserEarningsHistoryData = UserEarningsPayoutsResponse;

export type GetSpecifiedUserWalletBalanceData = WalletBalanceResponse;

export type GetAdminWalletBalanceData = WalletBalanceResponse;

export type DepositToUserWalletData = WalletBalanceResponse;

export type WithdrawFromUserWalletData = WalletBalanceResponse;

export type RefundWalletPaymentForOrderData = any;

export type GetBankTransferDetailsData = BankTransferDetails;

/** @format binary */
export type GenerateQrCodeData = File;

export type PayOrderViaPayoutGatewayData = PayoutPaymentGatewayUri;

/** @format byte */
export type HandlePayoutGatewayResponsePayload = string;

export type HandlePayoutGatewayResponseData = any;

export type PayOrderViaConfirmoGatewayData = ConfirmoPaymentGatewayUri;

/** @format byte */
export type HandleConfirmoGatewayHookPayload = string;

export type HandleConfirmoGatewayHookData = any;

export type PayOrderViaWalletData = WalletPaymentResponse;

export interface HandleWalletPaymentHookParams {
    /**
     * Token identifying the payment.
     * @format uuid
     */
    token: string;
}

export type HandleWalletPaymentHookData = any;

/** @format binary */
export type GeneratePdfData = File;

export type GetCountryCodesData = KycWellKnownCountries;

export type InitUserKycData = AuthologicKycResponse;

/** @format byte */
export type HandleAuthologicHookPayload = string;

export type HandleAuthologicHookData = any;

export type GetSumSubUserTokenData = SumSubTokenResponse;

export type GetUserWalletBalanceData = WalletBalanceResponse;

export interface GetLastEarningsPayoutIdData {
    /** ID of the user who called the method. */
    userId: CmsDatabaseId;
    /**
     * ID of last performed earnings payout.
     * Null if no import has been performed yet.
     * @format uuid
     */
    importId?: string;
}

export type GetUserEarningsHistoryData = UserEarningsPayoutsResponse;

/** @format binary */
export type GenerateTaxOverviewData = File;

/** @format byte */
export type HandleSumSubHookPayload = string;

export type HandleSumSubHookData = any;

export interface ExtBrokerLoginPayload {
    /** @example "external@user.email" */
    email: string;
    /** @example "password1234" */
    password: string;
}

export interface ExtBrokerLoginData {
    /** @example "xxxxx.yyyyy.zzzzz" */
    token: string;
    /** @format date-time */
    validTo: string;
}

export type ExtCreateUserData = BrokerCreateUserResponse;

export interface ExtGetProjectsParams {
    /**
     * Currency to get token prices in.
     * Defaults to euros.
     */
    currency?: CoreApiCurrency;
    /**
     * Language to get project details in.
     * Defaults to english.
     */
    language?: CoreApiLanguage;
}

export type ExtGetProjectsData = BrokerGetProjectsResponse;

export interface ExtGetProjectParams {
    /**
     * Currency to get token prices in.
     * Defaults to euros.
     */
    currency?: CoreApiCurrency;
    /**
     * Language to get project details in.
     * Defaults to english.
     */
    language?: CoreApiLanguage;
    /** Document ID of an entity saved in CMS. */
    projectId: CmsDocumentId;
}

export type ExtGetProjectData = BrokerGetProjectResponse;

export type ExtCreateOrderData = BrokerCreateOrderResponse;

export type ExtPayOrderData = any;

export namespace Default {
    /**
     * @description Welcome message including name, description, version and useful links
     * @tags Default
     * @name GetWelcomeMessage
     * @summary Welcome message
     * @request GET:/
     */
    export namespace GetWelcomeMessage {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetWelcomeMessageData;
    }

    /**
     * No description
     * @tags Default
     * @name GetOpenApi
     * @summary Get this specification.
     * @request GET:/open-api/specification.yaml
     */
    export namespace GetOpenApi {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetOpenApiData;
    }
}

export namespace StrapiWebhooks {
    /**
     * @description Creates new Audit log entry based on [Strapi CMS webhook](https://docs.strapi.io/dev-docs/backend-customization/webhooks). **⚠️ Please note** that only hooks whose `event` starts with `entry.` prefix are supported.
     * @tags Strapi webhooks
     * @name CreateAuditLog
     * @summary Create new Audit log entry
     * @request POST:/hook/strapi/audit-log
     * @secure
     */
    export namespace CreateAuditLog {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = StrapiWebHook;
        export type RequestHeaders = {};
        export type ResponseBody = CreateAuditLogData;
    }

    /**
     * @description Synchronize an Order created, modified or deleted in Strapi CMS with HubSpot.
     * @tags Strapi webhooks
     * @name SynchronizeOrderWithHubspot
     * @summary Synchronize an Order with HubSpot
     * @request POST:/hook/strapi/order
     * @secure
     */
    export namespace SynchronizeOrderWithHubspot {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = StrapiWebHook;
        export type RequestHeaders = {};
        export type ResponseBody = SynchronizeOrderWithHubspotData;
    }

    /**
     * @description Synchronize a User created, modified or deleted in Strapi CMS with HubSpot.
     * @tags Strapi webhooks
     * @name SynchronizeUserWithHubspot
     * @summary Synchronize a User with HubSpot
     * @request POST:/hook/strapi/user
     * @secure
     */
    export namespace SynchronizeUserWithHubspot {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = StrapiWebHook;
        export type RequestHeaders = {};
        export type ResponseBody = SynchronizeUserWithHubspotData;
    }
}

export namespace StrapiOperations {
    /**
     * @description Create new tokens for project in Strapi CMS database and link them with the project.
     * @tags Strapi operations
     * @name CreateTokens
     * @summary Create tokens for project
     * @request POST:/hook/strapi/token/create
     * @secure
     */
    export namespace CreateTokens {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = CreateTokensRequest;
        export type RequestHeaders = {};
        export type ResponseBody = CreateTokensData;
    }

    /**
     * @description Synchronize a User registration form submit with HubSpot using [Forms API](https://developers.hubspot.com/docs/api/marketing/forms).
     * @tags Strapi operations
     * @name SynchronizeUserSignupWithHubspotForm
     * @summary Synchronize a User sign up form with HubSpot.
     * @request POST:/hook/strapi/user/sign-up
     * @secure
     */
    export namespace SynchronizeUserSignupWithHubspotForm {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = StrapiForm;
        export type RequestHeaders = {};
        export type ResponseBody = SynchronizeUserSignupWithHubspotFormData;
    }

    /**
     * @description Prepare CSV file with all project shares per user to pay earnings to the users. The file is intended to be downloaded, processed externally (in spreadsheet editor) and uploaded back once done to process the earnings payout.
     * @tags Strapi operations
     * @name PrepareEarningsCsv
     * @summary Prepare CSV for paying earnings
     * @request GET:/hook/strapi/earnings/prepare
     * @secure
     */
    export namespace PrepareEarningsCsv {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = PrepareEarningsCsvData;
    }

    /**
     * @description Process the uploaded file and pay the earnings from the file to users' wallets.
     * @tags Strapi operations
     * @name ProcessEarningsCsv
     * @summary Process earnings from input file
     * @request POST:/hook/strapi/earnings/process
     * @secure
     */
    export namespace ProcessEarningsCsv {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ProcessEarningsCsvPayload;
        export type RequestHeaders = {};
        export type ResponseBody = ProcessEarningsCsvData;
    }

    /**
     * @description Returns history of earnings payouts (imported from the CSV file) ordered from newest imports to latest. In the future, we may introduce some pagination and sorting parameters but now it returns everything.
     * @tags Strapi operations
     * @name GetEarningsImportsHistory
     * @summary Return history of earnings payouts.
     * @request GET:/hook/strapi/earnings/history
     * @secure
     */
    export namespace GetEarningsImportsHistory {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetEarningsImportsHistoryData;
    }

    /**
     * @description Returns complete history of user's earnings payouts sorted by date from oldest to latest.
     * @tags Strapi operations
     * @name GetSpecifiedUserEarningsHistory
     * @summary Obtains history of earnings payouts for a user.
     * @request GET:/hook/strapi/earnings/user/{userId}/history
     * @secure
     */
    export namespace GetSpecifiedUserEarningsHistory {
        export type RequestParams = {
            /** Database ID of the user. */
            userId: CmsDatabaseId;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetSpecifiedUserEarningsHistoryData;
    }

    /**
     * No description
     * @tags Strapi operations
     * @name GetSpecifiedUserWalletBalance
     * @summary Obtains wallet balance of the user
     * @request GET:/hook/strapi/wallet/user/{userId}/{currency}
     * @secure
     */
    export namespace GetSpecifiedUserWalletBalance {
        export type RequestParams = {
            /** Database ID of the user. */
            userId: CmsDatabaseId;
            /** Currency of wallet. */
            currency: CoreApiCurrency;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetSpecifiedUserWalletBalanceData;
    }

    /**
     * No description
     * @tags Strapi operations
     * @name GetAdminWalletBalance
     * @summary Obtains wallet balance of the admin
     * @request GET:/hook/strapi/wallet/user/admin/{currency}
     * @secure
     */
    export namespace GetAdminWalletBalance {
        export type RequestParams = {
            /** Currency of wallet. */
            currency: CoreApiCurrency;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetAdminWalletBalanceData;
    }

    /**
     * @description Deposits `amount` of funds to user's wallet denominated in `currency`.
     * @tags Strapi operations
     * @name DepositToUserWallet
     * @summary Deposit funds to user's wallet.
     * @request POST:/hook/strapi/wallet/user/{userId}/deposit
     * @secure
     */
    export namespace DepositToUserWallet {
        export type RequestParams = {
            /** Database ID of the user. */
            userId: CmsDatabaseId;
        };
        export type RequestQuery = {};
        export type RequestBody = WalletDepositEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = DepositToUserWalletData;
    }

    /**
     * @description Withdraws `amount` of funds from user's wallet denominated in `currency`.
     * @tags Strapi operations
     * @name WithdrawFromUserWallet
     * @summary Withdraw funds from user's wallet.
     * @request POST:/hook/strapi/wallet/user/{userId}/withdraw
     * @secure
     */
    export namespace WithdrawFromUserWallet {
        export type RequestParams = {
            /** Database ID of the user. */
            userId: CmsDatabaseId;
        };
        export type RequestQuery = {};
        export type RequestBody = WalletWithdrawEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = WithdrawFromUserWalletData;
    }

    /**
     * @description When an order is being canceled and it has been paid using wallet, the funds must be transferred back to the user's wallet.
     * @tags Strapi operations
     * @name RefundWalletPaymentForOrder
     * @summary Refund funds back to user.
     * @request POST:/hook/strapi/wallet/refund-order
     * @secure
     */
    export namespace RefundWalletPaymentForOrder {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = OrderRefundEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = RefundWalletPaymentForOrderData;
    }
}

export namespace PaymentOperations {
    /**
     * @description Get details about bank transfer to pay for order with document id `orderDocumentId`.
     * @tags Payment operations
     * @name GetBankTransferDetails
     * @summary Get details about bank transfer.
     * @request GET:/pay/bank-transfer/{orderDocumentId}
     * @secure
     */
    export namespace GetBankTransferDetails {
        export type RequestParams = {
            /** ID of order as saved in CMS to pay for. */
            orderDocumentId: CmsDocumentId;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetBankTransferDetailsData;
    }

    /**
     * @description Generates and returns (as SVG image) a QR code suitable for initiating account to account bank transfer.
     * @tags Payment operations
     * @name GenerateQrCode
     * @summary Get payment QR code.
     * @request GET:/pay/qr-code/{orderDocumentId}
     * @secure
     */
    export namespace GenerateQrCode {
        export type RequestParams = {
            /** ID of order as saved in CMS. Will be used as "variable symbol". */
            orderDocumentId: CmsDocumentId;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GenerateQrCodeData;
    }

    /**
     * @description Initialize payment request on Payout payment gateway for an Order. The method returns a URL to payment gateway where the user should be forwarded. After the payment is processed, the user is redirected to `returnUrl`.
     * @tags Payment operations
     * @name PayOrderViaPayoutGateway
     * @summary Initialize payment.
     * @request POST:/pay/payout-gateway
     * @secure
     */
    export namespace PayOrderViaPayoutGateway {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = PayoutPaymentGatewayEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = PayOrderViaPayoutGatewayData;
    }

    /**
     * @description Initialize payment request on Confirmo payment gateway for an Order. The method returns a URL to payment gateway where the user should be forwarded. After the payment is processed, the user is redirected to `returnUrl`.
     * @tags Payment operations
     * @name PayOrderViaConfirmoGateway
     * @summary Initialize payment on Confirmo.
     * @request POST:/pay/confirmo-gateway
     * @secure
     */
    export namespace PayOrderViaConfirmoGateway {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ConfirmoPaymentGatewayEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = PayOrderViaConfirmoGatewayData;
    }

    /**
     * @description The order can be paid from the user's wallet. It may be paid fully (if the balance of the wallet is high enough) or partially (then the user must pay the rest using other payment method).
     * @tags Payment operations
     * @name PayOrderViaWallet
     * @summary Pay the order from wallet.
     * @request POST:/pay/wallet
     * @secure
     */
    export namespace PayOrderViaWallet {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = WalletPaymentEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = PayOrderViaWalletData;
    }
}

export namespace PaymentOperationCallbacks {
    /**
     * No description
     * @tags Payment operation callbacks
     * @name HandlePayoutGatewayResponse
     * @summary Handle Payout payment gateway hook.
     * @request POST:/hook/pay/payout-gateway
     */
    export namespace HandlePayoutGatewayResponse {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = HandlePayoutGatewayResponsePayload;
        export type RequestHeaders = {};
        export type ResponseBody = HandlePayoutGatewayResponseData;
    }

    /**
     * No description
     * @tags Payment operation callbacks
     * @name HandleConfirmoGatewayHook
     * @summary Handle Confirmo payment gateway hook.
     * @request POST:/hook/pay/confirmo-gateway
     */
    export namespace HandleConfirmoGatewayHook {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = HandleConfirmoGatewayHookPayload;
        export type RequestHeaders = {
            "bp-signature"?: string;
        };
        export type ResponseBody = HandleConfirmoGatewayHookData;
    }

    /**
     * @description Pay the order fully from user's wallet. This is second step of the payment (first is `POST /pay/wallet`). The user must be logged in portal and the `token` must be valid.
     * @tags Payment operation callbacks
     * @name HandleWalletPaymentHook
     * @summary Confirm paying the order fully from wallet.
     * @request POST:/hook/pay/wallet
     * @secure
     */
    export namespace HandleWalletPaymentHook {
        export type RequestParams = {};
        export type RequestQuery = {
            /**
             * Token identifying the payment.
             * @format uuid
             */
            token: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = HandleWalletPaymentHookData;
    }
}

export namespace Misc {
    /**
     * No description
     * @tags Misc
     * @name GeneratePdf
     * @request GET:/pay/pdf
     * @secure
     */
    export namespace GeneratePdf {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GeneratePdfData;
    }
}

export namespace UserOperations {
    /**
     * No description
     * @tags User operations
     * @name GetCountryCodes
     * @summary Obtains collection of known countries.
     * @request GET:/user/kyc/countries
     * @secure
     */
    export namespace GetCountryCodes {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetCountryCodesData;
    }

    /**
     * No description
     * @tags User operations
     * @name InitUserKyc
     * @summary Initialize User's KYC process.
     * @request POST:/user/kyc/authologic/{flow}
     * @secure
     */
    export namespace InitUserKyc {
        export type RequestParams = {
            flow: AuthologicFlow;
        };
        export type RequestQuery = {};
        export type RequestBody = AuthologicKycEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = InitUserKycData;
    }

    /**
     * No description
     * @tags User operations
     * @name GetSumSubUserToken
     * @summary Obtains token for user verification using SumSub
     * @request GET:/user/kyc/sumsub/token
     * @secure
     */
    export namespace GetSumSubUserToken {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {
            /**
             * User token assigned by Strapi CMS.
             * @example "Bearer 21d88bb9e5c72b90dc1823596bfb271c"
             */
            Authorization: string;
        };
        export type ResponseBody = GetSumSubUserTokenData;
    }

    /**
     * No description
     * @tags User operations
     * @name GetUserWalletBalance
     * @summary Obtains wallet balance of the user
     * @request GET:/user/wallet/{currency}
     * @secure
     */
    export namespace GetUserWalletBalance {
        export type RequestParams = {
            /** Currency of wallet. */
            currency: CoreApiCurrency;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUserWalletBalanceData;
    }

    /**
     * @description The method returns ID of last import of performed earnings payout. The idea is that frontend will call this method and saves its result to local storage. Everytime the user enters "Overview" or "Earnings" page, frontend will call this method again. If the result is the same as value saved in local storage, then cached response from `GET /user/earnings/history` can be used for displaying the data. Otherwise frontend has to query the history again to get potentially updated data.
     * @tags User operations
     * @name GetLastEarningsPayoutId
     * @summary Returns ID of last earnings payout.
     * @request GET:/user/earnings/last-payout
     * @secure
     */
    export namespace GetLastEarningsPayoutId {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetLastEarningsPayoutIdData;
    }

    /**
     * @description Returns complete history of user's earnings payouts sorted by date from oldest to latest. The result should be cached in local storage to avoid calling the method to often (since it may be costly). To determine whether the method should be called again, call `GET /user/earnings/last-payout` first.
     * @tags User operations
     * @name GetUserEarningsHistory
     * @summary Obtains history of earnings payouts.
     * @request GET:/user/earnings/history
     * @secure
     */
    export namespace GetUserEarningsHistory {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUserEarningsHistoryData;
    }

    /**
     * No description
     * @tags User operations
     * @name GenerateTaxOverview
     * @request GET:/user/earnings/export/{year}
     * @secure
     */
    export namespace GenerateTaxOverview {
        export type RequestParams = {
            /**
             * Year to get overview for.
             * Must be at least 2024 and at most current year.
             * @min 2024
             */
            year: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GenerateTaxOverviewData;
    }
}

export namespace UserOperationCallbacks {
    /**
     * No description
     * @tags User operation callbacks
     * @name HandleAuthologicHook
     * @summary Handle Authologic KYC hook.
     * @request POST:/hook/user/authologic/kyc
     */
    export namespace HandleAuthologicHook {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = HandleAuthologicHookPayload;
        export type RequestHeaders = {
            "x-signature": string;
            /** @format int64 */
            "x-signature-timestamp": number;
        };
        export type ResponseBody = HandleAuthologicHookData;
    }

    /**
     * No description
     * @tags User operation callbacks
     * @name HandleSumSubHook
     * @summary Handle SumSub KYC hook.
     * @request POST:/hook/kyc/sumsub
     */
    export namespace HandleSumSubHook {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = HandleSumSubHookPayload;
        export type RequestHeaders = {};
        export type ResponseBody = HandleSumSubHookData;
    }
}

export namespace ExternalBrokerOperations {
    /**
     * @description Log in as a broker. Returned token should be used in consequent calls in `Authorization` header as `Bearer ${token}`.
     * @tags External broker operations
     * @name ExtBrokerLogin
     * @summary Login as external broker.
     * @request POST:/v1/broker/login
     */
    export namespace ExtBrokerLogin {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ExtBrokerLoginPayload;
        export type RequestHeaders = {};
        export type ResponseBody = ExtBrokerLoginData;
    }

    /**
     * @description Create new user in the system. The user will have random password set. The password could be later changed using "Forgotten password" link. The user has its profile partially filled (with AML data). The KYC data must be filled later using a link which has been sent to user's e-mail during the creation.
     * @tags External broker operations
     * @name ExtCreateUser
     * @summary Create new user in Strapi CMS.
     * @request POST:/v1/broker/users
     * @secure
     */
    export namespace ExtCreateUser {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = BrokerCreateUserEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = ExtCreateUserData;
    }

    /**
     * No description
     * @tags External broker operations
     * @name ExtGetProjects
     * @summary Get opened projects
     * @request GET:/v1/broker/projects
     * @secure
     */
    export namespace ExtGetProjects {
        export type RequestParams = {};
        export type RequestQuery = {
            /**
             * Currency to get token prices in.
             * Defaults to euros.
             */
            currency?: CoreApiCurrency;
            /**
             * Language to get project details in.
             * Defaults to english.
             */
            language?: CoreApiLanguage;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ExtGetProjectsData;
    }

    /**
     * No description
     * @tags External broker operations
     * @name ExtGetProject
     * @summary Get detail of project
     * @request GET:/v1/broker/projects/{projectId}
     * @secure
     */
    export namespace ExtGetProject {
        export type RequestParams = {
            /** Document ID of an entity saved in CMS. */
            projectId: CmsDocumentId;
        };
        export type RequestQuery = {
            /**
             * Currency to get token prices in.
             * Defaults to euros.
             */
            currency?: CoreApiCurrency;
            /**
             * Language to get project details in.
             * Defaults to english.
             */
            language?: CoreApiLanguage;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ExtGetProjectData;
    }

    /**
     * No description
     * @tags External broker operations
     * @name ExtCreateOrder
     * @summary Create new order for specified user.
     * @request POST:/v1/broker/orders
     * @secure
     */
    export namespace ExtCreateOrder {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = BrokerCreateOrderEnvelope;
        export type RequestHeaders = {};
        export type ResponseBody = ExtCreateOrderData;
    }

    /**
     * No description
     * @tags External broker operations
     * @name ExtPayOrder
     * @summary Pay for the order.
     * @request POST:/v1/broker/orders/{orderId}/pay
     * @secure
     */
    export namespace ExtPayOrder {
        export type RequestParams = {
            /** ID of the order returned by `/broker/orders`. */
            orderId: CmsDocumentId;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = ExtPayOrderData;
    }
}
