import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, PublicLayout } from "src/components";
import { Routes } from "src/routes";
import { useTranslation } from "src/translations";
import { logGtmEvent, useComponentDidMount } from "src/utils";

export const EmailVerifiedPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useComponentDidMount(() => {
        logGtmEvent({ event: "user_verified", onlyOnce: true });
    });

    return (
        <PublicLayout title={t("emailVerified.message")}>
            <Button fullWidth onClick={() => navigate(Routes.signIn.path)}>
                {t("emailVerified.login")}
            </Button>
        </PublicLayout>
    );
};
