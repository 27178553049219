import { useSearchParams } from "react-router-dom";

import { EHUB_REFERRAL_CODE_SEARCH_PARAM, REFERRAL_CODE_SEARCH_PARAM } from "./constants";

export const useSearchParamReferralCode = () => {
    const [searchParams] = useSearchParams();
    const referralCodeFromParam = searchParams.get(EHUB_REFERRAL_CODE_SEARCH_PARAM) ?? searchParams.get(REFERRAL_CODE_SEARCH_PARAM);
    return referralCodeFromParam;
};

export const isEhubReferralCode = (referralCode?: string): referralCode is string =>
    referralCode ? !!referralCode.match(/^[a-zA-Z0-9]{32}$/) : false;
