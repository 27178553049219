import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { Button, CmsError, PopupWindow, PublicLayout, SecretInput } from "src/components";
import { logger } from "src/errorHandling";
import { Routes } from "src/routes";
import { isCmsErrorResponseWithMessage, removeAuthToken, useResetPasswordMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { isNullish, passwordSchema } from "src/utils";

type FormFields = {
    password: string;
};

export const NewPasswordPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [resetPassword, { isLoading, isError, error }] = useResetPasswordMutation();
    const [searchParams] = useSearchParams();

    const NewPasswordSchema = z.object({
        password: passwordSchema(t),
    });

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>({
        resolver: zodResolver(NewPasswordSchema),
    });

    const onSubmit = async (formData: FormFields) => {
        const code = searchParams.get("code");
        if (isNullish(code)) {
            logger.error("No code provided when resetting password");
            return;
        }
        try {
            removeAuthToken();
            await resetPassword({ password: formData.password, passwordConfirmation: formData.password, code }).unwrap();
            await PopupWindow.fire({
                title: t("newPassword.popUp.success"),
                icon: "success",
                confirmButtonText: t("newPassword.popUp.login"),
            });
            navigate(Routes.signIn.path);
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <PublicLayout title={t("newPassword.card.header")}>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
                {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
                <SecretInput
                    label={t("newPassword.placeholder.password")}
                    {...register("password", { required: true })}
                    error={errors.password?.message}
                />
                <Button type="submit" fullWidth isLoading={isLoading} disabled={!isValid}>
                    {t("newPassword.button.submit")}
                </Button>
            </form>
        </PublicLayout>
    );
};
