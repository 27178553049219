import { type FC, useState } from "react";

import { getCurrencyConversionRate } from "src/config";
import { getUserCurrency } from "src/serverApi";
import { Trans, useTranslation } from "src/translations";
import type { ProjectInfo } from "src/types";
import { useAppSelector } from "src/utils";
import { InvestOrSignInButton } from "./InvestOrSignInButton";
import { ReturnOfInvestmentCalculation } from "./ReturnOfInvestmentCalculation";
import { ReturnOfInvestmentParameters } from "./ReturnOfInvestmentParameters";
import { ReturnOfInvestmentResults } from "./ReturnOfInvestmentResults";
import { calculateRoundedMedian, convertProjectCalculation } from "./utils";

type ProjectDetailCalculatorProps = Readonly<{
    projectInfo?: ProjectInfo;
    loading: boolean;
    isProjectInInvestState: boolean;
}>;

export const ProjectDetailCalculator: FC<ProjectDetailCalculatorProps> = ({ loading, projectInfo, isProjectInInvestState }) => {
    const { t } = useTranslation();

    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);

    const isCalculatorEnabled = projectInfo?.project?.isCalculatorEnabled;
    const informationProspectUrl = projectInfo?.informationProspect?.url || "";
    const { annualRentalIncome, incrementalPropertyValue, period, calculatorMin, calculatorMax } = projectInfo?.project?.calculation ?? {};
    const [convertedCalculatorMin, convertedCalculatorMax] = convertProjectCalculation(
        calculatorMin,
        calculatorMax,
        projectInfo?.project?.currency,
        userCurrency,
        conversionRate,
    );

    const [selectedInvestmentAmount, setSelectedInvestmentAmount] = useState<number>(
        calculateRoundedMedian(convertedCalculatorMin, convertedCalculatorMax),
    );

    if (!isCalculatorEnabled && !isProjectInInvestState) return null;

    return (
        <>
            <div className="z-20 inline-flex flex-col items-start justify-start gap-4 rounded-lg bg-white p-8 font-inter shadow-calculator lg:sticky lg:top-12 xl:top-4">
                {isCalculatorEnabled ? (
                    <>
                        <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                            <ReturnOfInvestmentCalculation
                                investmentAmount={selectedInvestmentAmount}
                                onInvestmentAmountChange={setSelectedInvestmentAmount}
                                currency={userCurrency}
                                maxCalculatorAmount={convertedCalculatorMax}
                                minCalculatorAmount={convertedCalculatorMin}
                                loading={loading}
                            />
                            <ReturnOfInvestmentParameters
                                annualRentalIncome={annualRentalIncome}
                                incrementalPropertyValue={incrementalPropertyValue}
                                loading={loading}
                            />
                        </div>
                        <ReturnOfInvestmentResults
                            annualRentalIncome={annualRentalIncome}
                            incrementalPropertyValue={incrementalPropertyValue}
                            period={period}
                            investmentAmount={selectedInvestmentAmount}
                            currency={userCurrency}
                            loading={loading}
                        />
                    </>
                ) : (
                    <p className="text-base font-normal text-black">{t("projects.detail.sidebar.calculator.disabled")}</p>
                )}
                {isProjectInInvestState && (
                    <div className="hidden w-full lg:flex">
                        <InvestOrSignInButton
                            projectSlug={projectInfo?.slug!}
                            onlyForCurrentInvestor={!!projectInfo?.project?.onlyForCurrentInvestor}
                        />
                    </div>
                )}
            </div>

            {isCalculatorEnabled && (
                <p className="mt-6 text-xs text-tertiary">
                    <Trans
                        i18nKey="projects.detail.sidebar.calculator.info"
                        components={{
                            br: <br />,
                            button: informationProspectUrl ? (
                                <button
                                    className="underline hover:text-black focus:outline-none focus:ring-2 focus:ring-black/50"
                                    type="button"
                                    aria-label={t("projects.detail.sidebar.calculator.ariaLabel")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(informationProspectUrl, "_blank");
                                    }}
                                />
                            ) : (
                                // Fragment due to typing of components prop
                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                <></>
                            ),
                        }}
                    />
                </p>
            )}
        </>
    );
};
