import type { DocumentId, GetVotingsParams, Voting, VotingListResponse, VotingVoteTypeEnum } from "src/types";
import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, VOTING_TAG } from "../tags";
import { getCmsUrl } from "../utils";

export const votingApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        voting: build.query<VotingListResponse, GetVotingsParams>({
            query: (params) => ({
                url: getCmsUrl("/api/votings"),
                method: HttpMethod.GET,
                params,
            }),
            providesTags: [VOTING_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        voteWeight: build.query<
            { weight: string | null },
            { voteType: VotingVoteTypeEnum; projectDocumentId: DocumentId; permission: Voting["permission"] }
        >({
            query: (params) => ({
                url: getCmsUrl("/api/votings/vote-weight"),
                method: HttpMethod.GET,
                params,
            }),
        }),
        vote: build.mutation<void, { votingDocumentId: DocumentId; optionKey: string }>({
            query: ({ votingDocumentId, optionKey }) => ({
                url: getCmsUrl("/api/votings/vote"),
                method: HttpMethod.POST,
                params: {
                    votingDocumentId,
                    optionKey,
                },
            }),
            invalidatesTags: [VOTING_TAG],
        }),
    }),
});

export const { useVotingQuery, useVoteWeightQuery, useVoteMutation } = votingApi;
