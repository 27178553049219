import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { OrderWalletAuthorizePage } from "./OrderWalletAuthorizePage";

export const orderWalletAuthorize = typeCheckAppModule({
    NAME,
    Container: OrderWalletAuthorizePage,
    route: Routes.orderWalletAuthorize,
});
