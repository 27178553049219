import type {
    BankTransferDetails,
    ConfirmoPaymentGatewayEnvelope,
    ConfirmoPaymentGatewayUri,
    DocumentId,
    OrderListResponse,
    OrderRequest,
    OrderResponse,
    PayoutPaymentGatewayEnvelope,
    PayoutPaymentGatewayUri,
} from "src/types";
import { OrderStateEnum } from "src/types";
import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, LOGGED_USER_TAG, ORDER_TAG, PROJECT_TAG, USER_PORTFOLIO_TAG } from "../tags";
import { getCmsUrl, getCoreUrl } from "../utils";

export const orderApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        ordersByUserId: build.query<OrderListResponse, number>({
            query: (userId) => ({
                url: getCmsUrl("/api/orders"),
                method: HttpMethod.GET,
                params: {
                    status: "published",
                    filters: {
                        user: { id: { $eq: userId } },
                    },
                    populate: {
                        project: {
                            fields: ["currency"],
                            populate: {
                                projectInfo: true,
                            },
                        },
                    },
                    sort: "createdAt:desc",
                },
            }),
            providesTags: [ORDER_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        order: build.query<OrderResponse, DocumentId>({
            query: (documentId) => ({
                url: getCmsUrl(`/api/orders/${documentId}`),
                method: HttpMethod.GET,
                params: {
                    status: "published",
                    populate: {
                        project: {
                            populate: {
                                images: true,
                                projectInfo: {
                                    populate: {
                                        documents: true,
                                    },
                                },
                            },
                        },
                    },
                },
            }),
            providesTags: [ORDER_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        orderState: build.query<OrderStateEnum | undefined, number>({
            query: (id) => ({
                url: getCmsUrl(`/api/orders/${id}`),
                method: HttpMethod.GET,
                params: {
                    status: "published",
                    fields: ["state"],
                },
            }),
            providesTags: [ORDER_TAG],
            transformResponse: (response: OrderResponse) => response.data?.state,
        }),
        createOrder: build.mutation<OrderResponse, OrderRequest>({
            query: (data) => ({
                url: getCmsUrl("/api/orders"),
                method: HttpMethod.POST,
                body: data,
            }),
            invalidatesTags: [LOGGED_USER_TAG, ORDER_TAG, USER_PORTFOLIO_TAG, PROJECT_TAG],
        }),
        cancelOrder: build.mutation<OrderResponse, DocumentId>({
            query: (documentId) => ({
                url: getCmsUrl(`/api/orders/${documentId}`),
                method: HttpMethod.PUT,
                body: {
                    data: {
                        state: OrderStateEnum.Canceled,
                    },
                },
            }),
            invalidatesTags: [ORDER_TAG, USER_PORTFOLIO_TAG],
        }),
        payoutPayment: build.mutation<PayoutPaymentGatewayUri, PayoutPaymentGatewayEnvelope>({
            query: (data) => ({
                url: getCoreUrl("/pay/payout-gateway"),
                method: HttpMethod.POST,
                body: data,
            }),
        }),
        confirmoPayment: build.mutation<ConfirmoPaymentGatewayUri, ConfirmoPaymentGatewayEnvelope>({
            query: (data) => ({
                url: getCoreUrl("/pay/confirmo-gateway"),
                method: HttpMethod.POST,
                body: data,
            }),
        }),
        qrPayment: build.query<BankTransferDetails, { documentId: DocumentId }>({
            query: ({ documentId }) => ({
                url: getCoreUrl(`/pay/bank-transfer/${documentId}`),
                method: HttpMethod.GET,
            }),
        }),
        authorizeWalletPayment: build.mutation<void, { token: string }>({
            query: ({ token }) => ({
                url: getCoreUrl("/hook/pay/wallet"),
                method: HttpMethod.POST,
                params: {
                    token,
                },
            }),
        }),
        walletPayment: build.mutation<void, { orderDocumentId: DocumentId; walletId: string; amount: number }>({
            query: (body) => ({
                url: getCoreUrl("/pay/wallet"),
                method: HttpMethod.POST,
                body,
            }),
        }),
    }),
});

export const {
    useOrdersByUserIdQuery,
    useOrderQuery,
    useCancelOrderMutation,
    useCreateOrderMutation,
    useConfirmoPaymentMutation,
    usePayoutPaymentMutation,
    useAuthorizeWalletPaymentMutation,
    useWalletPaymentMutation,
} = orderApi;
