import type { Article, ArticleListResponse, GetArticlesParams } from "src/types";
import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { ARTICLE_TAG, ENTITY_WITH_TRANSLATIONS_TAG } from "../tags";
import { getCmsUrl } from "../utils";

export const articleApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        articles: build.query<ArticleListResponse, GetArticlesParams>({
            query: (params) => ({
                url: getCmsUrl("/api/articles"),
                method: HttpMethod.GET,
                params: {
                    status: "published",
                    populate: {
                        image: true,
                        tags: true,
                        permissions: true,
                    },
                    ...params,
                },
            }),
            providesTags: [ARTICLE_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        articleByDocumentId: build.query<Article | null, string>({
            query: (documentId) => ({
                url: getCmsUrl("/api/articles"),
                method: HttpMethod.GET,
                params: {
                    status: "published",
                    filters: {
                        documentId: { $eq: documentId },
                    },
                    populate: {
                        image: true,
                        tags: true,
                        permissions: true,
                    },
                },
            }),
            transformResponse: (response: ArticleListResponse) => {
                const article = response?.data?.[0];
                if (!article) {
                    return null;
                }
                return article;
            },
            providesTags: (result) => [{ type: ARTICLE_TAG, id: result?.id }, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
    }),
});

export const { useArticlesQuery, useArticleByDocumentIdQuery } = articleApi;
