import { Arrow, Content, Portal, Provider, Root, Trigger } from "@radix-ui/react-tooltip";
import type { FC, ReactNode } from "react";

type TooltipProps = Readonly<{
    children: ReactNode;
    content: ReactNode;
}>;

export const Tooltip: FC<TooltipProps> = ({ children, content }) => (
    <Provider>
        <Root>
            <Trigger asChild>{children}</Trigger>
            <Portal>
                <Content
                    sideOffset={2}
                    className="max-w-[350px] rounded bg-brand-primary-foreground px-[8px] py-[6px] text-white shadow-md"
                >
                    {content}
                    <Arrow />
                </Content>
            </Portal>
        </Root>
    </Provider>
);
