import type { FC } from "react";

import { NoContent, Table, TableBody, TableHead, TableHeader, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import type { UserEarningsPayoutsResponse } from "src/types";
import { EarningItem } from "./EarningItem";

type EarningsTableProps = Readonly<{
    earnings?: UserEarningsPayoutsResponse;
}>;

export const EarningsTable: FC<EarningsTableProps> = ({ earnings }) => {
    const { t } = useTranslation();

    if (!earnings?.data?.length) {
        return <NoContent transKey="earnings.noContent" />;
    }

    return (
        <div className="max-w-full overflow-hidden">
            <Table className="bg-white">
                <TableHeader className="border-b border-primary">
                    <TableRow className="text-sm text-secondary [&>th]:px-3 [&>th]:pb-3">
                        <TableHead className="pl-0">{t("earnings.list.date")}</TableHead>
                        <TableHead>{t("earnings.list.project")}</TableHead>
                        <TableHead className="text-right">{t("earnings.list.amount")}</TableHead>
                        <TableHead className="text-right">{t("earnings.list.settlement")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {earnings.data.map((earning) => (
                        <EarningItem key={`${earning.timestamp}-${earning.projectId}`} earning={earning} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
