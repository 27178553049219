import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, CmsError, Input, Link, PopupWindow, PublicLayout, SecretInput, Separator } from "src/components";
import { Routes } from "src/routes";
import { isCmsErrorResponseWithMessage, removeAuthToken, setAuthToken, useLoginMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logGtmEvent, useComponentDidMount } from "src/utils";
import { GoogleButton } from "../google";
import { useUpdateProfileUser } from "../utils";
import { logger } from "./logger";

type FormFields = {
    identifier: string;
    password: string;
};

export const SignInPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const sessionExpired = searchParams.get("sessionExpired");

    useComponentDidMount(() => {
        if (sessionExpired) {
            PopupWindow.fire({
                title: t("signIn.sessionExpired.title"),
                text: t("signIn.sessionExpired.text"),
                icon: "warning",
            });
        }
    });

    const [login, { isLoading, isError, error }] = useLoginMutation();
    const updateUserProfile = useUpdateProfileUser();

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>();

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            const { jwt } = await login(data).unwrap();
            setAuthToken(jwt);
            updateUserProfile().then(() => {
                logGtmEvent({ event: "user_logged_in" });
                const next = searchParams.get("next");
                navigate(next ?? Routes.projects.path);
            });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <PublicLayout title={t("signIn.title")}>
            <GoogleButton />
            <form id="Login" onSubmit={handleSubmit(onSubmit)} className="w-full space-y-[40px]">
                {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
                <div className="space-y-6">
                    <Separator />
                    <Input
                        {...register("identifier", { required: true })}
                        type="text"
                        maxLength={70}
                        label={t("signIn.placeholder.email")}
                        error={errors.identifier?.message}
                        autoComplete="email"
                        fullWidth
                    />

                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span className="text-sm">{t("signIn.placeholder.password")}</span>
                            <Link size="compact" href={Routes.forgotPassword.path}>
                                {t("signIn.forgotPassword.link")}
                            </Link>
                        </div>
                        <SecretInput
                            {...register("password", { required: true })}
                            error={errors.password?.message}
                            fullWidth
                            autoComplete="current-password"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-5">
                    <Button type="submit" isLoading={isLoading} fullWidth disabled={!isValid}>
                        {t("signIn.button.login")}
                    </Button>
                    <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
                        <span className="text-sm">{t("signIn.register.label")}</span>
                        <Link href={Routes.signUp.path}>{t("signIn.register.link")}</Link>
                    </div>
                </div>
            </form>
        </PublicLayout>
    );
};
