import type { Currency, GetUserWalletBalanceData } from "src/types";
import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { WALLET_TAG } from "../tags";
import { getCoreUrl } from "../utils";

export const walletApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        walletBalance: build.query<GetUserWalletBalanceData, { currency: Currency }>({
            query: ({ currency }) => ({
                url: getCoreUrl(`/user/wallet/${currency}`),
                method: HttpMethod.GET,
            }),
            providesTags: [WALLET_TAG],
        }),
    }),
});

export const { useWalletBalanceQuery } = walletApi;
