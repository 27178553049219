import "./index.css";
// imports to setup polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-loading-skeleton/dist/skeleton.css";
import "react-gallery-carousel/dist/index.css";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { AppRouter } from "src/app";
import { ErrorBoundary } from "src/components";
import { ConfigLoader } from "src/config";
import { store } from "src/setup";
import { initTranslations } from "src/translations";
import { isDevelopment, printAppVersion } from "src/utils";

initTranslations();

if (isDevelopment) {
    printAppVersion();
}

const rootElement = document.getElementById("app");
if (rootElement === null) {
    throw new Error("Root element is null. App initialization failed.");
}
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <ConfigLoader>
                    <AppRouter />
                </ConfigLoader>
            </Provider>
        </ErrorBoundary>
    </StrictMode>,
);
