import type { FC } from "react";

import { DownloadLink, Heading, OrderLayout, PageLayout } from "src/components";
import { getAppConfig } from "src/config";
import { Routes } from "src/routes";
import { useOrderQuery, useProjectInfoDetailByDocumentIdQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { Currency, OrderStateEnum } from "src/types";
import {
    cleanArray,
    convertPriceInCentsToTargetCurrencyWithCentMultiply,
    getCurrentNominalPrice,
    getValOrThrowError,
    multiplyPriceInCents,
    useAppSelector,
} from "src/utils";

export const OrderDetailPage: FC = () => {
    const orderDocumentId = getValOrThrowError(Routes.orderDetail.useParams().orderDocumentId, "Missing order document id");
    const { t } = useTranslation();

    const appConfig = getValOrThrowError(useAppSelector(getAppConfig));
    const { data: orderData, isLoading: isLoadingOrder } = useOrderQuery(orderDocumentId);
    const projectInfoDocumentId = orderData?.data?.project?.projectInfo?.documentId;
    const { data: projectInfo, isLoading: isLoadingProject } = useProjectInfoDetailByDocumentIdQuery(projectInfoDocumentId!, {
        skip: !projectInfoDocumentId,
    });

    const order = orderData?.data;
    const project = projectInfo?.project;
    const tokenFee = project?.tokenFee ?? 0;
    const tokenPriceWithoutFee = getCurrentNominalPrice(project?.tokenCurrentNominalPrice);
    const tokenPrice = tokenPriceWithoutFee ? tokenPriceWithoutFee + tokenFee : null;
    const convertedTokenPrice = convertPriceInCentsToTargetCurrencyWithCentMultiply(
        tokenPrice ?? 0,
        project?.currency ?? Currency.EUR,
        order?.currency ?? Currency.EUR,
        appConfig.conversionRateEurToCzk,
    );

    const showDocuments = order?.state && [OrderStateEnum.Open, OrderStateEnum.Paid, OrderStateEnum.Unpaid].includes(order.state);
    const documents = cleanArray([appConfig.termsAndConditions, appConfig.whitePaper, projectInfo?.informationProspect]);

    return (
        <PageLayout loading={isLoadingOrder || isLoadingProject || !projectInfoDocumentId}>
            <OrderLayout
                title={t("orders.detail.orderNumber", { id: orderData?.data?.id })}
                projectInfo={projectInfo}
                orderState={order?.state}
            >
                <Heading level={6} className="normal-case">
                    {t("common.overview")}
                </Heading>
                <div className="!mb-5 grid grid-cols-2 gap-y-1.5 text-sm">
                    <div>{t("orders.detail.overview.sharesBought")}</div>
                    <div className="text-right">
                        {order?.tokenCount ? t("format.amount", { value: order.tokenCount, maximumFractionDigits: 0 }) : "-"}
                    </div>
                    <div>{t("orders.detail.overview.pricePerShare")}</div>
                    <div className="text-right">{t("format.currency", { value: convertedTokenPrice, currency: order?.currency })}</div>
                    <div className="font-medium">{t("orders.detail.overview.totalPricePaid")}</div>
                    <div className="text-right font-medium">
                        {t("format.currency", { value: multiplyPriceInCents(order?.price), currency: order?.currency })}
                    </div>
                </div>

                {showDocuments && (
                    <>
                        <Heading level={6} className="normal-case">
                            {t("orders.detail.overview.documents")}
                        </Heading>
                        <div className="flex flex-col gap-1">
                            {documents.map(
                                ({ id, url, caption }) =>
                                    url && (
                                        <DownloadLink key={id} href={url} className="text-sm">
                                            {caption}
                                        </DownloadLink>
                                    ),
                            )}
                        </div>
                    </>
                )}
            </OrderLayout>
        </PageLayout>
    );
};
