import { hasPropOfType, isObject, isString } from "src/utils";

export const isCmsErrorResponseWithMessage = (error: unknown): error is { data: { error: { message: string } } } =>
    isObject(error) &&
    hasPropOfType(error, "data", isObject) &&
    hasPropOfType(error.data, "error", isObject) &&
    hasPropOfType(error.data.error, "message", isString);

export const isCoreErrorResponseWithMessage = (error: unknown): error is { error: string } =>
    isObject(error) && hasPropOfType(error, "error", isString);
