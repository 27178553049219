import type { DocumentId } from "src/types";
import { rootApi } from "../rootApi";
import { getCmsUrl } from "../utils";

export const tokenApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        getAvailableTokensCountByProjectDocumentId: build.query<number, DocumentId>({
            query: (projectDocumentId) => ({
                url: getCmsUrl("/api/tokens/count"),
                params: {
                    status: "published",
                    filters: {
                        project: { documentId: { $eq: projectDocumentId } },
                        order: { $null: true }, // If token has an order, it is reserved
                        sold: { $eq: false },
                    },
                },
            }),
        }),
    }),
});

export const { useGetAvailableTokensCountByProjectDocumentIdQuery } = tokenApi;
