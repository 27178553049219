import type { UrlString } from "src/types";
import { AUTH_TOKEN } from "./constants";

/**
 * Gets base url for backend. This serves to create urls for API calls. Url is constructed based on <code>window.location</code>, so one FE build can be used for multiple environments.
 * This requires that all environments follow the same pattern.
 *
 * For local development, base url can be overridden by LOCAL_BE variable in .env file. This enables to locally develop FE against any BE.
 * If LOCAL_BE is not specified, then <code>window.location</code> is used, but without <code>api.</code> prefix.
 */

export const getCmsUrl = (apiUrl?: string): UrlString => {
    if (import.meta.env.DEV) {
        return apiUrl ?? "";
    }

    const urlRegex = /^(http|https):\/\//;
    if (apiUrl && urlRegex.test(apiUrl)) {
        return apiUrl;
    }
    if (import.meta.env.VITE_CMS_URL) {
        return `${import.meta.env.VITE_CMS_URL}${apiUrl ?? ""}`;
    }
    throw new Error("Environment variable VITE_CMS_URL is not set.");
};

export const getCoreUrl = (apiUrl: string): UrlString => {
    if (import.meta.env.VITE_CORE_URL) {
        return `${import.meta.env.VITE_CORE_URL}${apiUrl}`;
    }
    throw new Error("Environment variable VITE_CORE_URL is not set.");
};

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);

export const setAuthToken = (token?: string) => {
    if (token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
};

export const removeAuthToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
};
