import { type FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Checking, Heading, Loading, OrderLayout, Separator, TopBar, VerificationStepper } from "src/components";
import { Routes } from "src/routes";
import { getUser, useOrderQuery, useProjectInfoDetailByDocumentIdQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { OrderStateEnum, UserVerificationTypeEnum } from "src/types";
import { getValOrThrowError, logGtmEvent, multiplyPriceInCents, useAppSelector } from "src/utils";
import { useUserVerification } from "src/verification";
import { ConfirmoPay, PayoutPay } from "./components";

export const OrderPayPage: FC = () => {
    const orderDocumentId = getValOrThrowError(Routes.orderPay.useParams().orderDocumentId, "Missing Order Document Id");
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const checking = searchParams.get("checking");

    const { data: order, isLoading } = useOrderQuery(orderDocumentId, {
        pollingInterval: checking ? 5000 : undefined,
    });
    const projectInfoDocumentId = order?.data?.project?.projectInfo?.documentId;
    const { data: projectInfo, isLoading: isLoadingProject } = useProjectInfoDetailByDocumentIdQuery(projectInfoDocumentId!, {
        skip: !projectInfoDocumentId,
    });

    const { isVerificationDone } = useUserVerification();
    const isUserInvestor = isVerificationDone(UserVerificationTypeEnum.INVESTOR);
    const user = useAppSelector(getUser);

    const logPaymentTriggeredEvent = () =>
        logGtmEvent({
            event: isUserInvestor ? "payment_triggered" : "first_payment_triggered",
            invoiceId: orderDocumentId,
            currency: getValOrThrowError(order?.data?.currency),
            amount: multiplyPriceInCents(order?.data?.price),
            mail: getValOrThrowError(user?.email),
        });

    const isOrderPaid = order?.data?.state === OrderStateEnum.Paid;

    useEffect(() => {
        if (isOrderPaid) {
            logGtmEvent({
                event: "purchase",
                onlyOnce: true,
                invoiceId: orderDocumentId,
                propertyId: getValOrThrowError(projectInfo?.project?.documentId),
                currency: getValOrThrowError(order?.data?.currency),
                amount: multiplyPriceInCents(order?.data?.price),
            });
        }
    }, [isOrderPaid, order?.data?.currency, order?.data?.price, orderDocumentId, projectInfo?.project?.documentId]);

    if (isLoading || isLoadingProject || !projectInfoDocumentId) {
        return <Loading isFullPage />;
    }

    return (
        <>
            <div className="sticky top-0 bg-brand-white">
                {!isUserInvestor && <VerificationStepper activeVerificationType={UserVerificationTypeEnum.INVESTOR} />}
                <TopBar />
            </div>
            {isOrderPaid ? (
                <div className="mt-8 grid justify-items-center gap-4">
                    <Heading level={1}>{t("orders.pay.completed")}</Heading>
                    <Heading level={5}>{t("orders.pay.description")}</Heading>
                    <Button
                        onClick={() => {
                            navigate(Routes.projects.path);
                        }}
                    >
                        {t("orders.pay.backToProjects")}
                    </Button>
                </div>
            ) : (
                <OrderLayout title={t("orders.pay.heading")} projectInfo={projectInfo} openInNewTab>
                    <Heading level={6} className="mb-[3px] normal-case">
                        {t("common.overview")}
                    </Heading>
                    <div className="!mb-5 grid grid-cols-2 gap-y-1.5 text-sm">
                        <div>{t("orders.pay.shares")}</div>
                        <div className="text-right">
                            {order?.data?.tokenCount
                                ? t("format.amount", {
                                      value: order.data.tokenCount,
                                      maximumFractionDigits: 0,
                                  })
                                : "-"}
                        </div>
                        <div>{t("orders.pay.totalPrice")}</div>
                        <div className="text-right">
                            {t("format.currency", {
                                value: multiplyPriceInCents(order?.data?.price ?? 0),
                                currency: getValOrThrowError(order?.data?.currency),
                            })}
                        </div>
                        <div>{t("orders.pay.priceFromWallet")}</div>
                        <div className="text-right">
                            {t("format.currency", {
                                value: multiplyPriceInCents(order?.data?.pricePaidFromUserWallet ?? 0),
                                currency: getValOrThrowError(order?.data?.currency),
                            })}
                        </div>
                        <div>{t("orders.pay.price")}</div>
                        <div className="text-right font-bold">
                            {t("format.currency", {
                                value: multiplyPriceInCents((order?.data?.price ?? 0) - (order?.data?.pricePaidFromUserWallet ?? 0)),
                                currency: getValOrThrowError(order?.data?.currency),
                            })}
                        </div>
                    </div>
                    {checking ? (
                        <div className="mt-4">
                            <Checking
                                title={t("orders.pay.checking.title")}
                                description={t("orders.pay.checking.info")}
                                linkToStopChecking={Routes.orderPay.fillPathParams({ orderDocumentId: orderDocumentId! })}
                            />
                        </div>
                    ) : (
                        <div className="mt-8 grid gap-4">
                            <PayoutPay orderDocumentId={orderDocumentId} onPay={logPaymentTriggeredEvent} />
                            <Separator />
                            <ConfirmoPay orderDocumentId={orderDocumentId} onPay={logPaymentTriggeredEvent} />
                        </div>
                    )}
                </OrderLayout>
            )}
        </>
    );
};
