import { type FC, useMemo, useState } from "react";

import { ButtonWithIcon, PopupWindow, Select, SelectItem, Spinner } from "src/components";
import { DownloadIcon } from "src/icons";
import { useLazyExportEarningsQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import type { UserEarningsPayoutsResponse } from "src/types";
import { logger } from "../logger";

type EarningsExportButtonProps = {
    earnings: UserEarningsPayoutsResponse["data"];
};

export const EarningsExportButton: FC<EarningsExportButtonProps> = ({ earnings }) => {
    const { t } = useTranslation();
    const [triggerExport] = useLazyExportEarningsQuery();
    const [isLoading, setIsLoading] = useState(false);

    const yearOptions = useMemo(
        () => Array.from(new Set(earnings.map((earning) => new Date(earning.timestamp).getFullYear()).sort((a, b) => b - a))),
        [earnings],
    );

    const [year, setYear] = useState(yearOptions[0]);

    const handleExport = async () => {
        try {
            setIsLoading(true);
            const response = await triggerExport(year).unwrap();
            const blob = new Blob([response], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `investbay-earnings-${year}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            logger.error("Failed to export earnings", error);
            await PopupWindow.fire({
                title: t("earnings.export.error.title"),
                text: t("earnings.export.error.text"),
                icon: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="inline-flex items-center">
            <ButtonWithIcon
                icon={isLoading ? <Spinner /> : <DownloadIcon className="fill-light" />}
                disabled={isLoading}
                onClick={handleExport}
            >
                {t("earnings.export.button")}
            </ButtonWithIcon>
            <Select
                className="h-full"
                triggerClassName="h-12 rounded-l-none"
                value={year.toString()}
                onChange={(value) => setYear(Number(value))}
            >
                {yearOptions.map((option) => (
                    <SelectItem className="h-12" key={option} value={option.toString()}>
                        {option}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
};
